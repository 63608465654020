import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import './App.css'
import {auth, deleteComponent, fetchAuth, importComponent, ImportComponentResponse, LibraryItem, listComponents} from './service'
import {GoChevronDown, GoChevronRight} from 'react-icons/all'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


function App() {
	const [online, setOnline] = useState(false)
	const [items, setItems] = useState<LibraryItem[]>([])

	// import
	const [showImportFeedback, setShowImportFeedback] = useState(false)
	const [importResult, setImportResult] = useState<ImportComponentResponse | null>(null)

	const onClose = () => setShowImportFeedback(false)

	const onAuth = async (name: string, password: string) => {
		console.log('auth: ' + name)
		const result = await auth(name, password)
		setOnline(result)
		await refresh()
	}

	const refresh = async () => {
		const items = await listComponents()
		setItems(items)
	}

	const onImport = async (data: string) => {
		const rv = await importComponent(data)
		setImportResult(rv)
		setShowImportFeedback(true)
		await refresh()
	}

	const onDelete = async (id: string) => {
		const newItems = items.filter(x => x.id !== id)
		setItems(newItems)
		await deleteComponent(id)
	}

	return (
		<>
			<div className='container d-flex flex-column p-2'>
				<AuthForm online={online} onAuth={onAuth}/>
				{online && <>
					<ImportForm onImport={onImport}/>
					<div><button className='btn btn-primary mb-2' onClick={refresh}>Refresh</button></div>
					<LibraryView items={items} onDelete={onDelete}/>
				</>}
			</div>

			<Modal show={showImportFeedback} onHide={onClose}>
				<div className='m-2'>
					{importResult && <>
						<span>{importResult.success ? 'Import succeeded.' : 'Import failed.'}</span>
						{!importResult.success && <span><br/>Error: <strong>{importResult.message}</strong></span>}
						{importResult.op === 'created' && <div>New component was created: <strong>{importResult.component.name}</strong></div>}
						{importResult.op === 'updated' && <div>Existing component was updated: <strong>{importResult.component.name}</strong></div>}
					</>}
					{!importResult && 'Import failed.'}
				</div>
				<Modal.Footer>
					<Button variant='secondary' onClick={onClose}>OK</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


// Auth UI
type AuthFormProps = {
	online: boolean,
	onAuth: (name: string, password: string) => void,
}

const AuthForm = ({online, onAuth}: AuthFormProps) => {
	//const [name, setName] = useState('homer')
	//const [password, setPassword] = useState('}nAH\'9#{')
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')

	const onSubmit = (ev: FormEvent) => {
		ev.preventDefault()
		onAuth(name, password)
	}

	return (<>
		{!online && <>
			<form className='form-inline' onSubmit={onSubmit}>
				<label className='sr-only'>Name</label>
				<input type='text' className='form-control mb-2 mr-sm-2' id='name' placeholder='Name'
							 onChange={ev => setName(ev.target.value)}
							 defaultValue={name}/>

				<label className='sr-only'>Password</label>
				<input type='password' className='form-control mb-2 mr-sm-2' id='password' placeholder='Password'
							 onChange={ev => setPassword(ev.target.value)}
							 defaultValue={password}/>

				<button type='submit' className='btn btn-primary mb-2'>Login</button>
			</form>
		</>}
		{online && <>
			<div className='mb-2'>User: <strong>{name}</strong></div>
		</>}
	</>)
}


// Import UI

const readFile = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onerror = reject
		reader.onload = () => {
			const result = reader.result as string
			resolve(result)
		}
		reader.readAsText(file)
	})
}

type ImportFormProps = {
	onImport: (data: string) => void;
}

const ImportForm = ({onImport}: ImportFormProps) => {
	const onSelectFile = async (ev: ChangeEvent<HTMLInputElement>) => {
		const files = ev.target.files
		const file = files![0]
		const data = await readFile(file)
		onImport(data)
	}

	return (
		<div>
			<button className='btn btn-primary btn-file mb-2' type='button'>
				Import... <input type='file' onChange={onSelectFile}/>
			</button>
		</div>
	)
}


// Library UI
const cmp = (a: LibraryItem, b: LibraryItem) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1

const groupItems = (items: LibraryItem[]): LibraryGroup[] => {
	const generators = items.filter(x => x.type === 'component.generator').sort(cmp)
	const pvs = items.filter(x => x.type === 'component.pv').sort(cmp)
	const wts = items.filter(x => x.type === 'component.wt').sort(cmp)
	const storages = items.filter(x => x.type === 'component.storage').sort(cmp)
	const converters = items.filter(x => x.type === 'component.converter').sort(cmp)
	return [
		{name: 'Generator', items: generators},
		{name: 'PV', items: pvs},
		{name: 'Wind Turbine', items: wts},
		{name: 'Storage', items: storages},
		{name: 'Converter', items: converters},
	]
}

type LibraryViewProps = {
	items: LibraryItem[]
	onDelete: (id: string) => void
}

const LibraryView = ({items, onDelete}: LibraryViewProps) => {
	const [groups, setGroups] = useState<LibraryGroup[]>([])

	useEffect(() => {
		const rv = groupItems(items)
		setGroups(rv)
	}, [items])

	return (<LibraryTable groups={groups} onDelete={onDelete}/>)
}

type LibraryGroup = {
	name: string
	items: LibraryItem[]
}

type LibraryTableProps = {
	groups: LibraryGroup[]
	onDelete: (id: string) => void
}

const LibraryTable = ({groups, onDelete}: LibraryTableProps) => {
	return (
		<table className='table table-sm table-hover'>
			<thead>
				<tr>
					<th scope='col'>Name</th>
					<th/>
				</tr>
			</thead>
			<tbody>
				{groups.map(x =>
					<LibraryTableGroup group={x} key={x.name} onDelete={onDelete}/>)}
			</tbody>
		</table>
	)
}

type LibraryTableGroupProps = {
	group: LibraryGroup
	onDelete: (id: string) => void
}

const LibraryTableGroup = ({group, onDelete}: LibraryTableGroupProps) => {
	const [expanded, setExpanded] = useState(false)
	const [items, setItems] = useState(group.items)
	const toggle = () => setExpanded(!expanded)

	useEffect(() => {
		setItems(group.items)
	}, [group])

	return (
		<>
			<tr>
				<td colSpan={2} onClick={toggle} style={{cursor: 'pointer'}}>
					{expanded ? <GoChevronDown className='mr-1'/> : <GoChevronRight className='mr-1'/>}
					<strong>{group.name}</strong>
				</td>
			</tr>
			{expanded && items.map(x =>
				<tr key={x.id}>
					<td className='align-middle'>{x.name}</td>
					<td className='align-middle'>
						<button className='btn btn-sm btn-outline-danger' onClick={() => onDelete(x.id)}>Delete</button>
					</td>
				</tr>)}
		</>
	)
}

export default App
