// auth state
let state: {
    token: string,
    expiration: Date,
} | null = null


// get token
type TokenResponse = {
    access_token: string,
    expires_in: number,
}

export const auth = async (name: string, password: string): Promise<boolean> => {
    const url = `api/auth/token?key=${encodeURIComponent(name)}&secret=${encodeURIComponent(password)}&tag=library`
    const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: {'Content-Type': 'application/json'},
        body: '{}',
    })
    if (response.status !== 200) { return false }

    // save token
    const json = await response.json() as TokenResponse
    const d = new Date()
    d.setSeconds(d.getSeconds() + json.expires_in)
    state = {token: json.access_token, expiration: d}
    return true
}


// fetch with service token
export const fetchAuth = async (url: string, init: RequestInit) => {
    if (!state) { return }
    init.headers = {...init.headers, 'Authorization': `Bearer ${state.token}`}
    init.cache = 'no-cache'
    return fetch(url, init)
}

// methods
export type LibraryItem = {
    id: string,
    type: string,
    name: string,
}

type GetAllComponentsResponse = {
    success: boolean,
    message: string,
    components: LibraryItem[]
}

type DeleteComponentResponse = {
    success: boolean,
    message: string,
}

export type ImportComponentResponse = {
    success: boolean,
    message: string,
    op: string,
    component: LibraryItem,
}

export const listComponents = async (): Promise<LibraryItem[]> => {
    console.log('list components')
    const response = await fetchAuth('api/private/library/list', {
        method: 'POST',
        cache: 'no-cache',
        headers: {'Content-Type': 'application/json'},
        body: '{}',
    })
    if (response?.status !== 200) {
        console.log(response?.status)
        return []
    }
    const json = await response.json() as GetAllComponentsResponse
    console.log(json)
    console.log(json.message)
    return json.components
}

export const deleteComponent = async (id: string): Promise<DeleteComponentResponse | null> => {
    console.log('delete component')
    const response = await fetchAuth('api/private/library/delete', {
        method: 'POST',
        cache: 'no-cache',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({id}),
    })
    if (response?.status !== 200) {
        console.log(response?.status)
        return null
    }
    const json = await response.json() as DeleteComponentResponse
    console.log(json)
    console.log(json.message)
    return json
}

export const importComponent = async (data: string): Promise<ImportComponentResponse | null> => {
    console.log('import component')
    const response = await fetchAuth('api/private/library/import', {
        method: 'POST',
        cache: 'no-cache',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({data}),
    })
    if (response?.status !== 200) {
        console.log(response?.status)
        return null
    }
    const json = await response.json() as ImportComponentResponse
    console.log(json)
    console.log(json.message)
    return json
}
